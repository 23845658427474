import React from "react"
import Header from "../components/header"
import {Helmet} from "react-helmet"

export default () => (
  <React.Fragment>
    <Helmet>    
      <title>Chode Dynasty</title>
    </Helmet>

    <Header />
    <main>
      <div className="row cond">
        <div className="inner">
          <h1>Constitution</h1>

          <p>Chode Dynasty is a year-round, dynasty football league consisting of 10 teams.</p>

          <p>The Constitution listed below may not be inclusive of all facets of the game but are to be used as a guide for fair play. In situations not clearly defined by the Constitution, the Commissioner reserves the right to make a judgement call in the best interest of the league.</p>

          <h2>Table of Contents</h2>
          <nav>
            <ul>
              <li><a href="#governance">League Governance</a></li>
              <li><a href="#dues">League Dues</a></li>
              <li><a href="#rewards">Weekly Rewards</a></li>
              <li><a href="#rosters">Rosters</a></li>
              <li><a href="#waivers">Waivers & Trading</a></li>
              <li><a href="#draft">Rookie Draft</a></li>
              <li><a href="#schedule">Schedule & Playoffs</a></li>
              <li><a href="#scoring">Scoring</a></li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="row cond" id="governance">
        <div className="inner">
          <h2>League Governance</h2>
          <h3>Commissioner Site Powers</h3>
          <p>The Commissioner has the ability to (in appropriate situations) edit lineups (either current or past), add/drop players, push/undo trades, and edit game scores. Additionally the Commissioner can pause/resume the draft and set/remove draft picks for teams.</p>

          <h3>Dispute Arbitration</h3>
          <p>The Commissioner is the final authority on all league matters, excluding disputes with a clear conflict of interest. In situations where a league vote is put forth and the outcome is a tie, the Commissioner will always have the tiebreaking vote.</p>

          <h3>Rule Changes</h3>
          <p>In order to change an existing rule, add a new rule, or change a scoring setting, a vote must be called and receive a majority. The Commissioner has the option to veto a rule change.</p>

          <h3>Collusion</h3>
          <p>Collusion will not be tolerated. A trade will be vetoed if there is evident collusion and the guilty teams will be removed from the league and forfeit any dues paid. There is no voting on trades.</p>
        </div>
      </div>

      <div className="row cond" id="dues">
        <div className="inner">
          <h2>League Dues</h2>
          <h3>Entry Fees</h3>
          <ul>
            <li>$50 per team</li>
            <li>Must be paid before the draft begins</li>
          </ul>

          <h3>Penalties</h3>
          <p>If a team does not pay their dues by the designated date, they are not eligible to participate in the draft. It will be up to the Commissioner on whether or not to replace them.</p>

          <h3>Payouts</h3>
          <ul>
            <li>1st Place: $260 (52%)</li>
            <li>2nd Place: $60 (12%)</li>
            <li>3rd Place: $40 (8%)</li>
            <li>Weekly Reward Winners ($10/week x 14 weeks): $140 (28%)</li>
          </ul>
        </div>
      </div>

      <div className="row cond" id="rewards">
        <div className="inner">
          <h2>Weekly Rewards</h2>
          <ul>
            <li>For week 1 - week 14, each week will have a different challenge that will reward $10 to the winner</li>
            <li>The challenges will be decided on by the Commissioner and posted to the league website by the start of each year</li>
            <li>The winner will be announced and paid the reward no later than 1 week after the week's games end</li>
            <li>Rewards will be paid out via PayPal or Venmo. If a manager does not have either of these, cash will be rewarded in-person but not within a guaranteed amount of time.</li>
          </ul>
        </div>
      </div>

      <div className="row cond" id="rosters">
        <div className="inner">
          <h2>Rosters</h2>
          <p>There are no limits to how many players of one position can be rostered on a team.</p>

          <table className="styled">
            <thead>
              <tr>
                <th>Position</th>
                <th>Starters</th>
                <th>Notes</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>QB</td>
                <td>1</td>
                <td></td>
              </tr>

              <tr>
                <td>RB</td>
                <td>2</td>
                <td></td>
              </tr>

              <tr>
                <td>WR</td>
                <td>2</td>
                <td></td>
              </tr>

              <tr>
                <td>TE</td>
                <td>1</td>
                <td></td>
              </tr>

              <tr>
                <td>Flex (W/R/T)</td>
                <td>2</td>
                <td></td>
              </tr>

              <tr>
                <td>DEF</td>
                <td>1</td>
                <td></td>
              </tr>

              <tr>
                <td>BN</td>
                <td>16</td>
                <td></td>
              </tr>

              <tr>
                <td>IR</td>
                <td>5</td>
                <td>only players labeled "Out" will be allowed</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="row cond" id="waivers">
        <div className="inner">
          <h2>Waivers & Trading</h2>
          <h3>Waivers</h3>
          <p>A Continuous Waivers system will be used instead of Free Agency. Waivers clear at 3AM each day so everybody has a chance to catch up on the news and make decisions on who to pick up from the available player pool.</p>

          <ul>
            <li>Teams will receive $200 for a Free Agency Aquisition Budget (FAAB) at the start of the off-season</li>
            <li>FAAB dollars are tradeable</li>
          </ul>

          <h3>Trades</h3>
          <ul>
            <li>Trades will be processed right away. If collusion is suspected, the matter will be put to a vote.</li>
            <li>Trading is allowed during the offseason</li>
            <li>Trade deadline is the end of the 13th week</li>
            <li>Draft picks are tradeable up to 3 years in the future</li>
          </ul>
        </div>
      </div>

      <div className="row cond" id="draft">
        <div className="inner">
          <h2>Rookie Draft</h2>
          <ul>
            <li>The draft order will be assigned in inverse order of the previous year's standings</li>
            <li>There will be 4 rounds</li>
            <li>The draft will follow a linear format just like the NFL draft (1.01 has 2.01, 3.01, 4.01)</li>
            <li>Each pick will have a maximum time limit of 10 minutes</li>
            <li>Teams that do not pick within the 10 minute limit will have the computer auto-pick for them</li>
            <li>Trading draft picks is allowed during the draft</li>
          </ul>

          <p>Any undrafted rookie will be treated as a common FA. If they are unavailable in the database you must message the Commissioner to claim them and leave a spot open on your roster once they confirm your request. If no spot is open when the player becomes available then there will be no priority given to the team that claimed them.</p>
        </div>
      </div>

      <div className="row cond" id="schedule">
        <div className="inner">
          <h2>Schedule & Playoffs</h2>
          <h3>Regular Season</h3>
          <ul>
            <li>The regular season lasts from week 1 to week 15</li>
            <li>The match schedule will be randomly generated at the start of each season</li>
          </ul>

          <h3>Playoffs</h3>
          <ul>
            <li>Playoffs begin week 15 with the Championship game occurring week 17</li>
            <li>The top 5 teams from the regular season will be in the playoffs</li>
          </ul>

          <h3>Toilet Bowl</h3>
          <ul>
            <li>The bottom 5 teams at the end of the regular season will compete in the <a href="https://support.sleeper.app/leagues/fantasy-football-leagues/consolation-bracket-toilet-bowl" target="_blank" rel="noopener noreferrer">Toilet Bowl</a></li>
            <li>The lower bracket teams play to avoid battling for last place (<span role="img" aria-label="pile of poo">💩</span> King). The <strong>losing</strong> team from each round gets flushed to the next round</li>
            <li><strong>The Toilet Bowl has no bearing on draft order</strong></li>
          </ul>
        </div>
      </div>

      <div className="row cond" id="scoring">
        <div className="inner">
          <h2>Scoring</h2>

          <h3>Passing</h3>
          <table className="styled">
            <thead>
              <tr>
                <th>Type of Play</th>
                <th>Points</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Passing Yards</td>
                <td>+0.04 per yard (25 yards = 1 point)</td>
              </tr>

              <tr>
                <td>Passing TD</td>
                <td>+6</td>
              </tr>

              <tr>
                <td>2-Pt Conversion</td>
                <td>+2</td>
              </tr>

              <tr>
                <td>Pass Intercepted</td>
                <td>-2</td>
              </tr>
            </tbody>
          </table>

          <h3>Rushing</h3>
          <table className="styled">
            <thead>
              <tr>
                <th>Type of Play</th>
                <th>Points</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Rushing Yards</td>
                <td>+0.1 per yard (10 yards = 1 point)</td>
              </tr>

              <tr>
                <td>Rushing TD</td>
                <td>+6</td>
              </tr>

              <tr>
                <td>2-Pt Conversion</td>
                <td>+2</td>
              </tr>
            </tbody>
          </table>

          <h3>Receiving</h3>
          <table className="styled">
            <thead>
              <tr>
                <th>Type of Play</th>
                <th>Points</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Reception</td>
                <td>+0.5</td>
              </tr>

              <tr>
                <td>Receiving Yards</td>
                <td>+0.1 per yard (10 yards = 1 point)</td>
              </tr>

              <tr>
                <td>Receiving TD</td>
                <td>+6</td>
              </tr>

              <tr>
                <td>2-Pt Conversion</td>
                <td>+2</td>
              </tr>
            </tbody>
          </table>

          <h3>Special Teams Player</h3>
          <table className="styled">
            <thead>
              <tr>
                <th>Type of Play</th>
                <th>Points</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Special Teams TD</td>
                <td>+6</td>
              </tr>

              <tr>
                <td>Special Teams Forced Fumble</td>
                <td>+1</td>
              </tr>

              <tr>
                <td>Special Teams Fumble Recovery</td>
                <td>+1</td>
              </tr>
            </tbody>
          </table>

          <h3>Special Teams Defense</h3>
          <table className="styled">
            <thead>
              <tr>
                <th>Type of Play</th>
                <th>Points</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Special Teams TD</td>
                <td>+6</td>
              </tr>

              <tr>
                <td>Special Teams Forced Fumble</td>
                <td>+1</td>
              </tr>

              <tr>
                <td>Special Teams Fumble Recovery</td>
                <td>+1</td>
              </tr>
            </tbody>
          </table>

          <h3>Team Defense</h3>
          <table className="styled">
            <thead>
              <tr>
                <th>Type of Play</th>
                <th>Points</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Points Allowed 0</td>
                <td>+5</td>
              </tr>

              <tr>
                <td>Points Allowed 1-6</td>
                <td>+4</td>
              </tr>

              <tr>
                <td>Points Allowed 7-13</td>
                <td>+3</td>
              </tr>

              <tr>
                <td>Points Allowed 14-20</td>
                <td>+1</td>
              </tr>

              <tr>
                <td>Points Allowed 28-34</td>
                <td>-1</td>
              </tr>

              <tr>
                <td>Points Allowed 35+</td>
                <td>-4</td>
              </tr>

              <tr>
                <td>Less Than 100 Total Yards Allowed</td>
                <td>+5</td>
              </tr>

              <tr>
                <td>100-199 Total Yards Allowed</td>
                <td>+3</td>
              </tr>

              <tr>
                <td>200-299 Total Yards Allowed</td>
                <td>+2</td>
              </tr>

              <tr>
                <td>350-399 Total Yards Allowed</td>
                <td>-1</td>
              </tr>

              <tr>
                <td>400-449 Total Yards Allowed</td>
                <td>-5</td>
              </tr>

              <tr>
                <td>450-499 Total Yards Allowed</td>
                <td>-5</td>
              </tr>

              <tr>
                <td>500-549 Total Yards Allowed</td>
                <td>-6</td>
              </tr>

              <tr>
                <td>500+ Total Yards Allowed</td>
                <td>-7</td>
              </tr>

              <tr>
                <td>Sack</td>
                <td>+1</td>
              </tr>

              <tr>
                <td>Interception</td>
                <td>+2</td>
              </tr>

              <tr>
                <td>Fumble Recovery</td>
                <td>+2</td>
              </tr>

              <tr>
                <td>Defensive TD</td>
                <td>+6</td>
              </tr>

              <tr>
                <td>Safety</td>
                <td>+2</td>
              </tr>

              <tr>
                <td>Forced Fumble</td>
                <td>+1</td>
              </tr>

              <tr>
                <td>Blocked Kick</td>
                <td>+2</td>
              </tr>
            </tbody>
          </table>

          <h3>Misc.</h3>
          <table className="styled">
            <thead>
              <tr>
                <th>Type of Play</th>
                <th>Points</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Fumble</td>
                <td>-1</td>
              </tr>

              <tr>
                <td>Fumble Lost</td>
                <td>-2</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>

    <footer className="row">
      <div className="inner">
        Last Updated: 05-21-24
      </div>
    </footer>
  </React.Fragment>
)